import {
  trackAddPortfolioButtonClicked,
  trackAddProductsButtonClicked,
  trackFrequencyIdleButtonClick,
  trackFrequencyLiveButtonClick,
  trackFrequencyPauseButtonClick,
  trackFrequencyRecommendedButtonClick,
  trackGuideButtonClicked,
} from "segment/ribbon";
import { isLiveFrequencyWarningDismissed, showLiveFrequencyWarning } from "utils/localStorage";

async function navigateToGuide(event: Office.AddinCommands.Event) {
  trackGuideButtonClicked();
  await global.Sparta.userNavigate("guide");

  event.completed();
}

async function navigateToPortfolios(event: Office.AddinCommands.Event) {
  trackAddPortfolioButtonClicked();
  await global.Sparta.userNavigate("liveCurves/portfolios");

  event.completed();
}

async function navigateToProducts(event: Office.AddinCommands.Event) {
  trackAddProductsButtonClicked();
  await global.Sparta.userNavigate("liveCurves/products");

  event.completed();
}

function pauseFrequency(event: Office.AddinCommands.Event) {
  trackFrequencyPauseButtonClick(global.Sparta.updateFrequency);
  global.Sparta.updateFrequency = "pause";

  event.completed();
}

async function liveFrequency(event: Office.AddinCommands.Event) {
  if (isLiveFrequencyWarningDismissed()) {
    trackFrequencyLiveButtonClick(global.Sparta.updateFrequency);
    global.Sparta.updateFrequency = "live";
  } else {
    await showLiveFrequencyWarning();
  }

  event.completed();
}

function recommendedFrequency(event: Office.AddinCommands.Event) {
  trackFrequencyRecommendedButtonClick(global.Sparta.updateFrequency);
  global.Sparta.updateFrequency = "recommended";

  event.completed();
}

function idleFrequency(event: Office.AddinCommands.Event) {
  trackFrequencyIdleButtonClick(global.Sparta.updateFrequency);
  global.Sparta.updateFrequency = "idle";

  event.completed();
}

Office.actions.associate("navigateToGuide", navigateToGuide);
Office.actions.associate("navigateToPortfolios", navigateToPortfolios);
Office.actions.associate("navigateToProducts", navigateToProducts);
Office.actions.associate("pauseFrequency", pauseFrequency);
Office.actions.associate("liveFrequency", liveFrequency);
Office.actions.associate("recommendedFrequency", recommendedFrequency);
Office.actions.associate("idleFrequency", idleFrequency);
