import { Frequency } from "types";

const WELCOME_ACCEPTED_KEY = "WELCOME_ACCEPTED";
const SELECTED_FREQUENCY_KEY = "SELECTED_FREQUENCY";
export const HIDE_INFO_FREQUENCY_KEY = "HIDE_INFO_FREQUENCY";

const SHOW_LIVE_WARNING_KEY = "SHOW_LIVE_WARNING";
const DISMISS_LIVE_WARNING_KEY = "DISMISS_LIVE_WARNING";

const VALID_FREQUENCIES: Frequency[] = ["live", "recommended", "idle", "pause"];

export const isWelcomeAccepted = () => !!localStorage.getItem(WELCOME_ACCEPTED_KEY);

export const acceptWelcome = () => localStorage.setItem(WELCOME_ACCEPTED_KEY, "true");

const isFrequencyInfoHidden = () => !!localStorage.getItem(HIDE_INFO_FREQUENCY_KEY);

const hideFrequencyInfo = () => localStorage.setItem(HIDE_INFO_FREQUENCY_KEY, "true");

export const getSelectedFrequency = (): Frequency => {
  const selectedFrequency = localStorage.getItem(SELECTED_FREQUENCY_KEY);

  if (selectedFrequency && VALID_FREQUENCIES.includes(selectedFrequency as Frequency)) {
    return selectedFrequency as Frequency;
  }

  return "recommended";
};

export const setSelectedFrequency = (frequency: Frequency) => {
  localStorage.setItem(SELECTED_FREQUENCY_KEY, frequency);
};

export const showLiveFrequencyWarning = () => {
  localStorage.setItem(SHOW_LIVE_WARNING_KEY, "true");

  return global.Sparta.openTaskPane();
};

export const hideLiveFrequencyWarning = () => {
  localStorage.removeItem(SHOW_LIVE_WARNING_KEY);
};

export const isLiveFrequencyWarningShown = () => !!localStorage.getItem(SHOW_LIVE_WARNING_KEY);

export const isLiveFrequencyWarningDismissed = () => !!localStorage.getItem(DISMISS_LIVE_WARNING_KEY);

export const dismissLiveFrequencyWarning = () => localStorage.setItem(DISMISS_LIVE_WARNING_KEY, "true");

export const clearLocalStorage = () => {
  const welcomeAccepted = isWelcomeAccepted();
  const frequencyInfoHidden = isFrequencyInfoHidden();
  const selectedFrequency = getSelectedFrequency();
  const liveWarningDismissed = isLiveFrequencyWarningDismissed();

  localStorage.clear();

  if (welcomeAccepted) {
    acceptWelcome();
  }

  if (frequencyInfoHidden) {
    hideFrequencyInfo();
  }

  if (liveWarningDismissed) {
    dismissLiveFrequencyWarning();
  }

  setSelectedFrequency(selectedFrequency);
};
