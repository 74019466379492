export const segmentEnabledEnvs = ["production"];

const SEGMENT_TIMEOUT: number = 30000;

/**
 * The Segment Identify call lets you tie a user to their actions and record traits about them. It includes a unique
 * User ID and any optional traits you know about the user, like their email, name, and more.
 * @param {string} userId - Unique identifier for the user in your database. A userId or an anonymousId is required.
 * @param {Record<string, any> | undefined} traits - Free-form dictionary of traits of the user, like email or name. See the [Traits field docs]{@link https://segment.com/docs/connections/spec/identify#traits Segment Traits} for a list of reserved trait names.
 */
export const identify = (userId: string, traits: Record<string, any> | undefined): void | null => {
  if (
    segmentEnabledEnvs.indexOf(process.env.SPARTA_ENV || "") === -1 ||
    !window.analytics ||
    !window.analytics.identify ||
    typeof window.analytics.identify !== "function"
  ) {
    console.log('Environment not enabled. Would be logged "Identify user" event with this payload:', {
      userId,
      ...traits,
    });
    return null;
  }

  try {
    return window.analytics.identify(userId, { ...traits });
  } catch (error) {
    return null;
  }
};

/**
 * The page call lets you record whenever a user sees a page.
 * @param {boolean} delayed - Delay the call of the page tracker to the page by the value of SEGMENT_TIMEOUT.
 */
export const page = (delayed = false): void | null => {
  if (segmentEnabledEnvs.indexOf(process.env.SPARTA_ENV || "") === -1) {
    console.log('Environment not enabled. Would be logged "Loaded a page"');

    return null;
  }

  if (!window.analytics || !window.analytics.page || typeof window.analytics.page !== "function") {
    if (!delayed) {
      setTimeout(() => page(), SEGMENT_TIMEOUT);
    }

    return null;
  }

  return window.analytics.page();
};

/**
 * The track API call is how you record any actions your users perform, along with any properties that describe the
 * action.
 * @param {string} name - Event name. E.g. Error Add Product Screen.
 * @param {Record<string, any>} payload - Parameters requested for the event.
 * @param {boolean} delayed - Delay the call of the page tracker to the page by the value of SEGMENT_TIMEOUT.
 */
export const track = (name: string, payload = {}, delayed = false): void | null => {
  if (segmentEnabledEnvs.indexOf(process.env.SPARTA_ENV || "") === -1) {
    console.log(`Environment not enabled. Would be logged "${name}" event with this payload:`, {
      ...payload,
      environment: process.env.SPARTA_ENV,
      versions: {
        addInVersion: process.env.EXCEL_BUNDLE_VERSION,
        excelBuild: Office.context.diagnostics.version,
        timestamp: new Date(),
      },
    });

    return null;
  }

  if (!window.analytics || !window.analytics.track || typeof window.analytics.track !== "function") {
    if (!delayed) {
      setTimeout(() => track(name, payload, true), SEGMENT_TIMEOUT);
    }

    return null;
  }

  return window.analytics.track(name, {
    ...payload,
    environment: process.env.SPARTA_ENV,
    versions: {
      addInVersion: process.env.EXCEL_BUNDLE_VERSION,
      excelBuild: Office.context.diagnostics.version,
    },
  });
};

/**
 * Calling reset resets the id, including anonymousId, and clears traits for the currently identified user and group.
 */
export const resetUser = (): void | null => {
  if (!window.analytics || !window.analytics.reset || typeof window.analytics.reset !== "function") {
    return null;
  }

  return window.analytics.reset();
};
