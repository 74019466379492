import { track } from "segment";
import { Frequency } from "types";

/**
 * When the user clicks on the Add Portfolio button in the ribbon menu.
 */
export const trackAddPortfolioButtonClicked = (): void => {
  track("Add Portfolio Button Clicked");
};

/**
 * When the user clicks on the Add Products button in the ribbon menu.
 */
export const trackAddProductsButtonClicked = (): void => {
  track("Add Products Button Clicked");
};

/**
 * When the user clicks on the 'Live' button on the riboon menu to change the frequency of updates to real-time updates.
 * @param oldFrequency the frequency button selected before
 */
export const trackFrequencyLiveButtonClick = (oldFrequency: Frequency) =>
  track("Frequency Live Clicked", { oldFrequency });

/**
 * When the user clicks on the 'Recommended' button on the riboon menu to change the frequency of updates to real-time updates.
 * @param oldFrequency the frequency button selected before
 */
export const trackFrequencyRecommendedButtonClick = (oldFrequency: Frequency) =>
  track("Frequency Recommended Clicked", { oldFrequency });

/**
 * When the user clicks on the 'Idle' button on the riboon menu to change the frequency of updates to real-time updates.
 * @param oldFrequency the frequency button selected before
 */
export const trackFrequencyIdleButtonClick = (oldFrequency: Frequency) =>
  track("Frequency Idle Clicked", { oldFrequency });

/**
 * When the user clicks on the 'Pause' button on the riboon menu to change the frequency of updates to real-time updates.
 * @param oldFrequency the frequency button selected before
 */
export const trackFrequencyPauseButtonClick = (oldFrequency: Frequency) =>
  track("Frequency Pause Clicked", { oldFrequency });

/**
 * When the user clicks on the Guide button of the ribbon menu to open the quick guide in the task pane.
 */
export const trackGuideButtonClicked = () => track("Guide Menu Clicked");

/**
 * When the info message is shown on the ‘Add Portfolio’ or the ‘Add Products’ screens to notify the user about the possibility of changing the frequency of updates through the frequency buttons in the ribbon menu.
 */
export const trackInfoFrequencyMessageShown = () => track("Info Frequency Buttons");
